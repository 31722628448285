<template>

    <section id="get-in-touch">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="form">
                        <form id="form-get-in-touch" @submit.prevent="submitForm">
                            <div class="form__title">{{ getInTouch.title }}</div>
                            <div class="form__row">
                                <label class="form__label" for="first_name">
                                    {{ getInTouch.firstName }}
                                    <span class="msg-required">{{ errors.first('firstName') }}</span>
                                </label>
                                <input
                                        v-validate.disable="'required|min:3'"
                                        v-model="firstName"
                                        name="firstName"
                                        class="form__inp"
                                        id="first_name"/>
                            </div>
                            <div class="form__row">
                                <label class="form__label" for="last_name">
                                    {{ getInTouch.lastName }}
                                    <span class="msg-required">{{ errors.first('lastName') }}</span>
                                </label>
                                <input
                                        v-validate.disable="'required|min:3'"
                                        v-model="lastName"
                                        name="lastName"
                                        class="form__inp"
                                        id="last_name"/>
                            </div>
                            <div class="form__row">
                                <label class="form__label" for="email">
                                    {{ getInTouch.email }}
                                    <span class="msg-required">{{ errors.first('email') }}</span>
                                </label>
                                <input
                                        v-validate.disable="'required|email'"
                                        v-model="email"
                                        name="email"
                                        class="form__inp"
                                        id="email"/>
                            </div>
                            <div class="form__row">
                                <label class="form__label" for="message">
                                    {{ getInTouch.msg }}
                                    <span class="msg-required">{{ errors.first('message') }}</span>
                                </label>
                                <textarea
                                        v-validate.disable="'required|min:3'"
                                        v-model="message"
                                        name="message"
                                        class="form__textarea"
                                        id="message"></textarea>
                            </div>
                            <button class="form__btn">{{ getInTouch.send }}</button>
                        </form>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cancel-sub">
                        <div class="cancel-sub__title">
                            <div class="title__row title__red">{{ getInTouch.cancelYourSubscription }}</div>
                            <div class="title__row">{{ getInTouch.inAOneClick }}</div>
                            <div class="title_row">{{ getInTouch.ourOnlineCancel }}</div>
                        </div>
                        <p class="cancel-sub__p">{{ getInTouch.text1 }}</p>
                        <p class="cancel-sub__p">{{ getInTouch.text2 }}</p>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="desc__block">{{ getInTouch.desc1 }}</div>
                                <div class="desc__block">{{ getInTouch.desc2 }}</div>
                            </div>
                            <div class="col-md-6">
                                <div class="desc__block">{{ getInTouch.desc3 }}</div>
                                <div class="desc__block">{{ getInTouch.desc4 }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loader v-if="showLoader"/>
        <Modal v-if="showModal" />
    </section>

</template>

<script>
    import {mapState, mapMutations} from 'vuex';
    import Modal from '../Modal/Index';
    import Loader from "../Loader/Loader";

    export default {
        name: "GetInTouch",
        components: {
            Modal,
            Loader,
        },
        data(){
            return{
                firstName:'',
                lastName:'',
                email:'',
                message:'',
            }
        },
        computed:{
            ...mapState([
                'vocab', 'locale', 'routes', 'showModal','showLoader',
            ]),
            getInTouch(){
                return this.vocab[this.locale].getInTouch;
            },
        },
        methods:{
            submitForm(){
                this.$validator.validate().then(result => {
                    if (result) {
                        this.showLoaderHandler();
                        this.axios.post(
                            this.routes.getintouch,
                            {
                                firstName: this.firstName.trim(),
                                lastName: this.lastName.trim(),
                                email: this.email.trim(),
                                message: this.message.trim(),
                            },
                            {
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                            }
                        )
                            .then(res => {
                                this.hideLoaderHandler();
                                if(res.data.status==='ok'){
                                    this.setModalText(this.vocab[this.locale].mailSent);
                                    console.log(res);
                                    this.clearForm();
                                }

                            })
                            .catch(err => {
                                this.hideLoaderHandler();
                                console.log(err);
                                this.setModalText(err);
                            })
                    }else {
                        return;
                    }
                });
            },

            clearForm() {
                this.firstName = "";
                this.lastName = "";
                this.email = "";
                this.message = "";
            },
        ...mapMutations([
            'setModalText', 'errorEmailInput', 'clearErrorStatus', 'hideLoaderHandler', 'showLoaderHandler'
        ])
        }
    }
</script>

<style scoped>

</style>